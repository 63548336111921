import {createSlice} from '@reduxjs/toolkit';


const initialState = {
 
modals:[]
};


const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
       appendModal: (state, action) => { 
        state.modals =[...state.modals,action.payload]
       },
       destroyModal: (state) => {
        
        state.modals = state.modals.slice(0,state.modals.length-1)
        },
    },
});

export const {appendModal,destroyModal} = modalSlice.actions;
export default modalSlice.reducer;