import {GiGarlic} from 'react-icons/gi'
import {CiWheat} from 'react-icons/ci'


export const İData =[
    {
        catId:'traditional',
        id:'1',
        name:'Keşkek',
        desc:'%50 dana eti %50 buğdayla hazırlanan karışım, 12 saat odun ateşinde pişirilir. Sonrasında büyük bir kaşık yardımıyla yeterli homojen kıvam elde edilince kadar dövülür. Üzerine salça, toz biber ve tereyağ karışımı sos eklenerek servis edilir. ',
        price:'115.00',
        img:'https://media.finedinemenu.com/filters:strip_exif()/filters:format(webp)/270x270/53YV3ePwGJ/7ecef7c2-e9f8-4a71-beb7-e44123707050.jpg',
        icon:[
            {
                id:'1',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'2',
                img:<CiWheat></CiWheat>
            }
        ]
    },
    {
        catId:'traditional',
        id:'2',
        name:'Mantı',
        price:'110.00',
        img:'https://media.finedinemenu.com/filters:strip_exif()/filters:format(webp)/90x90/53YV3ePwGJ/a10c0a7d-cbff-4b03-9fc4-8ef77758cb75.jpg',
        icon:[
            {
                id:'1',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'2',
                img:<CiWheat></CiWheat>
            },
            {
                id:'3',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'4',
                img:<CiWheat></CiWheat>
            }
        ]
    },
    {
        catId:'traditional',
        id:'3',
        name:'Keşkek',
        desc:'%50 dana eti %50 buğdayla hazırlanan karışım, 12 saat odun ateşinde pişirilir. Sonrasında büyük bir kaşık yardımıyla yeterli homojen kıvam elde edilince kadar dövülür. Üzerine salça, toz biber ve tereyağ karışımı sos eklenerek servis edilir. ',
        price:'115.00',
        img:'https://media.finedinemenu.com/filters:strip_exif()/filters:format(webp)/270x270/53YV3ePwGJ/7ecef7c2-e9f8-4a71-beb7-e44123707050.jpg',
        icon:[
            {
                id:'1',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'2',
                img:<CiWheat></CiWheat>
            }
        ]
    },
    {
        catId:'traditional',
        id:'4',
        name:'Mantı',
        price:'110.00',
        img:'https://media.finedinemenu.com/filters:strip_exif()/filters:format(webp)/90x90/53YV3ePwGJ/a10c0a7d-cbff-4b03-9fc4-8ef77758cb75.jpg',
        icon:[
            {
                id:'1',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'2',
                img:<CiWheat></CiWheat>
            },
            {
                id:'3',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'4',
                img:<CiWheat></CiWheat>
            }
        ]
    },
    {
        catId:'grid',
        id:'5',
        name:'Kaşarlı Köfte',
        price:'220.00',
        img:'https://media.finedinemenu.com/filters:strip_exif()/filters:format(webp)/270x270/53YV3ePwGJ/89ea3f84-b89b-4f5d-9861-ad90d7739990.jpeg',
        icon:[
            {
                id:'1',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'2',
                img:<CiWheat></CiWheat>
            },
            {
                id:'3',
                img:<GiGarlic></GiGarlic>
            },
            {
                id:'4',
                img:<CiWheat></CiWheat>
            }
        ]
    }


]