import { useSelector } from "react-redux";
import store from "../store";
import {appendModal,destroyModal} from '../store/modal';


export const useModals = () => useSelector(state => state.modal.modals);
export const createModal = (name,data=false) => {store.dispatch(appendModal({
    name,
    data
}))};
export const closeModal = () => {store.dispatch(destroyModal())};