import ModalsMap  from "../ModalsMap"
import {createModal, useModals} from '../utils/modal'
import modal from "../store/modal"

export default function Modal() {

    const modal = useModals()
    return (
        <>
        {
            modal.map((item,index) => {
                const {name,data} = item
                const Element = ModalsMap.find(modal => modal.name === name).Element
                return <Element key={index} item={data}/>
            }
            )
        }
        </>
    )
    }

