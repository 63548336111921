import React from 'react'
import { Link } from 'react-router-dom'

const İtemCard = ({item}) => {
  return (
    <Link to={"/detail/"+item.sno} key={item.id} className='flex items-center justify-between gap-1 w-full p-2 bg-white shadow-xl shadow-black/15 rounded-md'>
        <div className='flex flex-col items-start justify-between gap-2 p-2 flex-1'>
            <h1 className='text-lg font-light'>
                {
                    item.name
                }
            </h1>
            {
                item.desc?<p className='whitespace-normal line-clamp-3 text-sm font-light'>
                {
                    item.desc
                }
                 </p>:null
            }
            <span className='flex items-center justify-center gap-2'>
                <a className='text-orange-400'>
                ₺
                 {
                    item.price
                 }
               </a>
                <a>
                    porsiyon
                </a>
            </span>
            
        </div>
        <div className='w-1/3 '>
            <img src={process.env.REACT_APP_BASE_URL+item.img} className='rounded-md w-36' ></img>
        </div>
    </Link>
  )
}

export default İtemCard