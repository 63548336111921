import { closeModal } from "../utils/modal"
import {IoCloseCircleSharp} from 'react-icons/io5'
import {useState} from 'react'
import {GET, POST} from '../utils/ServerControl'
export default function Add({item}) {

   
   const [name, setName] = useState(item.name)
   const [file, setFile] = useState('')
     const[img, setImg] = useState('')
     const filehandel = (e) => {
            setImg(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0])
     }

   const uploadHandel = () => {
    POST(process.env.REACT_APP_KATEGORI,'add',[{name:'name',value:name},{name:'file',value:file}])
         .finally(()=>{
            window.location.reload()
         })
      }
      
      
   



   return (
        <div className="w-full z-50  h-screen absolute top-0 left-0 p-2 bg-slate-300 rounded-lg">
            <div className="flex items-center justify-end w-full ">
               <IoCloseCircleSharp size={44} onClick={closeModal}></IoCloseCircleSharp>
            </div>
            <h1 className="text-orange-400 font-semibold text-xl ml-4 mb-4">Kategori Ekle</h1>
            <div className="flex flex-col items-center justify-center gap-4">
               <span className="flex w-full  items-center justify-center bg-white rounded-md">
                    <label htmlFor="name" className="text-black font-semibold uppercase text-sm whitespace-nowrap border-r border-black p-2 h-full ">Kategori Adı</label>
                    <input id="name" value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="w-full rounded-md outline-none p-2 valid:bg-white"></input>
               </span>
            {
               img?<img src={img}></img>:<span className="flex w-full  items-center justify-center bg-white rounded-md"><label htmlFor="file" className="text-white w-full text-center bg-indigo-600 font-semibold uppercase text-sm whitespace-nowrap p-2 h-full ">Resim seçiniz</label><input id="file" onChange={(e)=>{filehandel(e)}} type="file" className="w-full hidden  rounded-md outline-none p-2"></input></span>
            }
            <span className="w-full">
            <button onClick={()=>{uploadHandel()}} className={!name || !file?'w-full h-12 tracking-wider pointer-events-none text-white bg-green-200  ':'  w-full h-12 tracking-wider text-white bg-green-500'}>
                  Kaydet
               </button>
            </span>
            </div>
        </div>
    )

}
