import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MoneyFormat } from '../utils/money';
import { POST } from '../utils/ServerControl';
import Nav from '../Components/Nav';

const İtemDetail = () => {

    const{id} = useParams();
    const [item,setİtem] = useState([]);
    useEffect(()=>{
     POST(process.env.REACT_APP_URUN,'selectDetail',[{name:'sno',value:id}]).then(res=>{
          setİtem(res[0])
          console.log(res[0])
      }
      )
    },[])
  return (
    <div className='w-full h-screen flex flex-col items-center justify-start gap-2'>
      <Nav back={true} icon={false} menu={true}></Nav>
      <div className='w-full flex-1 flex items-center flex-col justify-start bg-yellow-200'>
      <div className='min-h-[20rem] w-full bg-center bg-cover bg-no-repeat' style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL+item.img})`}}></div>
      <div className='w-full flex-1 flex-col bg-white rounded-t-3xl -mt-12 p-5 gap-8'>
        <span className='w-full'>
          <h1 className='text-3xl'>
            {item.name}
          </h1>
        </span>
        <span>
          <p className='  line-clamp-6'>
            {item.info}
          </p>
        </span>
        <span>
          <h1 className='text-2xl'>
            {
              MoneyFormat(item.price)
            }
          </h1>
        </span>

      </div>
      </div>
    </div>

  )
}

export default İtemDetail