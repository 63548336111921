import React, { useEffect } from 'react'
import {AiOutlinePlus} from 'react-icons/ai'
import { Menu } from '@headlessui/react'
import {useState} from 'react'
import {createModal,useModals} from '../../../utils/modal'
import Modal from '../../../modals'
import { POST } from '../../../utils/ServerControl'




const İtemControl = ({data,setData}) => {

  
  const modals = useModals()

  const modalHandel = () => {
    createModal('İtemAdd')
  }

  const [name, setName] = useState()
  useEffect(() => {
      
     POST(process.env.REACT_APP_URUN, 'selectCat',[]).then((res) => {
        setName(res)
      
        
      }
      )
  
    }
    , [])

    const filterHandel = (item) => {
      const filter = name.filter((i)=>{return i.categories === item.item.categories})
      setData(filter)
     
    }

  return (
    <div className='  relative flex flex-row items-center justify-between gap-4 '>
   {
      modals.map((item,index)=>{
        return <Modal key={index} name={item.name} component={item.component}></Modal>
      }
      )
   }
    <span onClick={()=>{modalHandel()}} className='inline-flex items-center justify-center rounded-md bg-green-600  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
      <h1>Ekle</h1>  
      <AiOutlinePlus size={20}></AiOutlinePlus>
    </span> 
   
        
    <Menu as="div" className="relative inline-block text-left flex-1  ">
    
    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-500 text-white text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
      Kategori Seç
    </Menu.Button>
    <Menu.Items className="origin-top-right absolute left-0 mt-2 w-full overflow-hidden rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
    <div className='flex items-center flex-col overflow-hidden gap-0.5 w-full flex-1'>
    <Menu.Item as='button' onClick={()=>{setData(name)}} className='bg-indigo-400 w-full text-white text-left p-2'>
      Hepsi
    </Menu.Item>
    {
      name?.map((item,index)=>{
        return <Menu.Item key={index} as='button' className='bg-indigo-400 w-full text-white text-left p-2' onClick={()=>{filterHandel({item})}}>{item.categories}</Menu.Item>
      })
    }
    </div>
    </Menu.Items>

    </Menu>
            
         
        


    
  
  </div>
  )
}

export default İtemControl