import React, { useEffect } from 'react'
import { useState } from 'react'
import { POST } from '../../../utils/ServerControl'

const AdminLogo = () => {
    const[logo,setLogo] = useState('')
    const [preview, setPreview] = useState('')
    useEffect(() => {
        POST(process.env.REACT_APP_SETTING, 'select',[]).then((res) => {
          setPreview(process.env.REACT_APP_BASE_URL+res[0].logo)
        })
      }, [])

    const updateHandel = () => {
    }
  return (

   
    
   
        
      <span className='w-full flex flex-col items-center h-screen justify-center gap-1'>
       
        <div className='w-full flex items-center justify-center'>
            {
        !logo?<img src={preview} className='w-full h-auto'></img>:<img src={URL.createObjectURL(logo)} className='w-full h-auto'></img>
            }
        </div>
        <label htmlFor='file' className={logo?'text-base text-white text-center p-1 py-3 font-bold w-full bg-green-400 border-dashed border-black border rounded-md':'text-base text-black text-center p-1 py-3 font-bold w-full bg-white border-dashed border-black border rounded-md'}>
          {logo?logo.name:'Logo seçmek için tıklayın'}
        </label>
        <input onChange={(e)=>{setLogo(e.target.files[0])}} id='file' type='file' className='hidden ' ></input>
        <button onClick={updateHandel()} className='w-full p-2 rounded-md bg-orange-400 text-white font-bold'>Kaydet</button>

      </span>

   
  )
}

export default AdminLogo