import { closeModal } from "../utils/modal"
import {IoCloseCircleSharp} from 'react-icons/io5'
import {useEffect, useState} from 'react'
import {GET, POST} from '../utils/ServerControl'
export default function İtemAdd() {

   
   const [name, setName] = useState('')
   const [file, setFile] = useState('')
   const[img, setImg] = useState('')
   const[desc, setDesc] = useState('')
   const[price, setPrice] = useState('')
   const[cat, setCat] = useState('')
   const [categories, setCategories] = useState([])


    const filehandel = (e) => {
            setImg(URL.createObjectURL(e.target.files[0]))
            setFile(e.target.files[0])
     }

    useEffect(() => {
        POST(process.env.REACT_APP_KATEGORI,'select',[]).then((res)=>{
            setCategories(res)
        })
        }, [])

    const uploadHandel = () => {
         POST(process.env.REACT_APP_URUN,'add',[{name:'name',value:name},{name:'file',value:file},{name:'desc',value:desc},{name:'price',value:price},{name:'cat',value:cat}])
        .finally(()=>{
            window.location.reload()
        })
        }
        
    



   return (
        <div className="w-full z-50  h-screen absolute top-0 left-0 p-2 bg-slate-300 rounded-lg">
            <div className="flex items-center justify-end w-full ">
               <IoCloseCircleSharp size={44} onClick={closeModal}></IoCloseCircleSharp>
            </div>
            <h1 className="text-orange-400 font-semibold text-xl ml-4 mb-4">Ürün Ekle</h1>
            <div className="flex flex-col items-center justify-center gap-4">
    
            <span className="flex w-full  items-center justify-center bg-white rounded-md">
                <label htmlFor="name" className="text-black font-semibold uppercase text-sm whitespace-nowrap border-r border-black p-2 h-full ">Ürün Adı</label>
                <input id="name" onChange={(e)=>{setName(e.target.value)}} type="text" className="w-full rounded-md outline-none p-2 valid:bg-white"></input>
            </span>
            <span className="flex w-full  items-center justify-center bg-white rounded-md">
                <label htmlFor="desc" className="text-black font-semibold uppercase text-sm whitespace-nowrap border-r border-black p-2 h-full ">Açıklama</label>
                <input id="desc" onChange={(e)=>{setDesc(e.target.value)}} type="text" className="w-full rounded-md outline-none p-2 valid:bg-white"></input>
            </span>
            <span className="flex w-full  items-center justify-center bg-white rounded-md">
                <label htmlFor="price" className="text-black font-semibold uppercase text-sm whitespace-nowrap border-r border-black p-2 h-full ">Fiyat</label>
                <input id="price" onChange={(e)=>{setPrice(e.target.value)}} type="number" className="w-full rounded-md outline-none p-2 valid:bg-white"></input>
            </span>
            <span className="w-full">
                <select onChange={(e)=>{setCat(e.target.value)}} className="w-full rounded-md outline-none p-2 valid:bg-white">
                    <option value="0">Kategori Seçiniz</option>
                    {
                        categories.map((item,index)=>{
                            return <option key={index} value={item.name}>{item.name}</option>
                        }
                        )
                    }
                </select>
            </span>
               
            <span className="w-full">
            {
                img?<img src={img} className="w-full"></img>:null
            }
            </span>
            <span className="flex w-full  items-center justify-center bg-white rounded-md">
            <label htmlFor="file" className="text-white w-full text-center bg-indigo-600 font-semibold uppercase text-sm whitespace-nowrap p-2 h-full ">Resim seçiniz</label>
            <input id="file" onChange={(e)=>{filehandel(e)}} type="file" className="w-full hidden  rounded-md outline-none p-2"></input>
            </span>
                    

           
            <span className="w-full">
            <button onClick={(e)=>{uploadHandel(e)}}  className={!name || !file?'w-full h-12 tracking-wider pointer-events-none text-white bg-green-200  ':'  w-full h-12 tracking-wider text-white bg-green-500'}>
                  Kaydet
               </button>
            </span>
            </div>
        </div>
    )

}
