import Add from "./modals/Add";
import Edit from "./modals/Edit";
import İtemAdd from "./modals/İtemAdd";
import İtemEdit from "./modals/İtemEdit";

const ModalsMap = [
    {
        name: 'add',
        Element: Add
    }
    ,
    {
        name: 'edit',
        Element: Edit
    }
    ,
    {
        name:'İtemAdd',
        Element:İtemAdd

    }
    ,
    {
        name:'İtemEdit',
        Element:İtemEdit
    }
]

export default ModalsMap