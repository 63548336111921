import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import Menu from './pages/Menu';
import İtem from './pages/İtem';
import İtemDetail from './pages/İtemDetail'
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import AdminCategories from './pages/admin/pages/AdminCategories';
import AdminCode from './pages/admin/pages/AdminCode';
import AdminSettings from './pages/admin/pages/AdminSettings';
import Adminİtem from './pages/admin/pages/Adminİtem';
import { Provider } from 'react-redux';
import store from './store';
import './i18n';
import 'flowbite';

import FeedBack from './pages/FeedBack';
import AdminLogo from './pages/admin/pages/AdminLogo';
import AdminBackground from './pages/admin/pages/AdminBackground';
import AdminContact from './pages/admin/pages/AdminContact';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/menu' element={<Menu></Menu>}>
            <Route path='/menu/:id' element={<İtem></İtem>}></Route>          
        </Route>
        <Route path='/detail/:id' element={<İtemDetail></İtemDetail>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/Dashboard' element={<Dashboard/>}>
          <Route path='/Dashboard/categories' element={<AdminCategories/>}></Route>
          <Route path='/Dashboard/items' element={<Adminİtem/>}></Route>
          <Route path='/Dashboard/code' element={<AdminCode/>}></Route>
         
        </Route>
        <Route path='/Dashboard/settings' element={<AdminSettings/>}>
            <Route path='/Dashboard/settings/logo' element={<AdminLogo/>}></Route>
            <Route path='/Dashboard/settings/background' element={<AdminBackground/>}></Route>
            <Route path='/Dashboard/settings/contact' element={<AdminContact/>}></Route>
        </Route>
        <Route path='/FeedBack' element={<FeedBack/>}></Route>
      </Routes>
    </BrowserRouter>
    </Provider>
);

