import React from 'react'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import { useState } from 'react'
import {RxCross2} from 'react-icons/rx'
import {TbMessageLanguage} from 'react-icons/tb'
import { useEffect } from 'react'
import { POST } from '../utils/ServerControl'
import {AiFillStar} from 'react-icons/ai'
import {BsPhone} from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import {WhatsappShareButton,WhatsappIcon,FacebookIcon,FacebookShareButton} from 'react-share'
const Sidebar = ({size}) => {

    const [open,setOpen] = useState(false);
    const [data,setData] = useState('')
  useEffect(() => {
    POST(process.env.REACT_APP_SETTING,'select',[]).then(res=>{
      setData(res[0])
    }
    )
  },[])

  return (
   <div>
   <HiOutlineMenuAlt2  className={open?'text-white':' shadow-2xl shadow-black p-1 rounded-lg'} size={size} onClick={()=>{setOpen(!open)}}></HiOutlineMenuAlt2>
   <div className={open?'w-full z-50 bg-white  top-0 left-0 h-screen absolute flex flex-col items-start justify-start p-4 transition-all opacity-100 delay-200':'hidden opacity-0'}>
        <div className='w-full  flex flex-col items-end justify-start z-10 '>
            <RxCross2 className='z-20 shadow-xl p-1 rounded-lg' onClick={()=>{setOpen(false)}} color='black' size={size}></RxCross2>
        </div>
       <div className='flex flex-col gap-16 items-center justify-between w-full h-full'>
       <div className='w-full items-center justify-center flex'>
          <img src={process.env.REACT_APP_BASE_URL+data.logo} className='w-40'></img>
        </div>
        <div className='w-full flex flex-col gap-3 h-full flex-1'>
          {/* <span className='flex gap-2 items-center justify-start'>
          <TbMessageLanguage size={18} color='grey'></TbMessageLanguage>
          <label className='text-lg text-slate-500 font-medium cursor-pointer'>Dil(TR)</label>
          </span> */}
          <Link to="/FeedBack" className='flex gap-2 items-center justify-start'>
          <AiFillStar size={18} color='grey'></AiFillStar>
          <label className='text-lg text-slate-500 font-medium cursor-pointer'>Bizi değerlendirin</label>
         </Link>
          <span className='w-full border  border-slate-100'>

          </span>
          <span className='flex gap-2 items-center justify-start'>
            <BsPhone size={16} color='grey'></BsPhone>
          <label className='text-lg text-slate-500 font-normal cursor-pointer'>Bizi arayın</label>
          </span>
        </div>
       </div>
       <div className='flex flex-row gap-5 items-center justify-center w-full'>
          <FacebookShareButton url={process.env.REACT_APP_BASE_URL}  >
          <FacebookIcon size={32} color='#a19754 border cursor-pointer' className='rounded-full'/>
          </FacebookShareButton>
          <WhatsappShareButton url={process.env.REACT_APP_BASE_URL}  >
          <WhatsappIcon size={32} color='#a19754 border cursor-pointer' className='rounded-full'/>
          </WhatsappShareButton>
          <a href={data.instagram}>
          <BsInstagram size={32} >
          </BsInstagram>
          </a>
          
       </div>
   </div>
   </div>
  )
}

export default Sidebar