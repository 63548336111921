import React, { useEffect } from 'react'
import Card from '../components/Card'
import {AiOutlinePlus} from 'react-icons/ai'
import Controls from '../components/Controls'
import { useState } from 'react'
import { GET, POST } from '../../../utils/ServerControl'

const AdminCategories = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    POST(process.env.REACT_APP_KATEGORI, 'select',[]).then((res) => {
      setData(res)
    })
  }, [])


  return (
    <div className='flex flex-col py-4 gap-4 relative h-screen'>
      <Controls data={data} setData={setData}></Controls>
        {
        data?.map((item,index) => {
          return <Card key={index} item={item}></Card>
        }
        )
        }
      
    </div>
  )
}

export default AdminCategories