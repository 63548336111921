import React, { useEffect } from 'react'
import { useState } from 'react'
import { BiLogoFacebookCircle, BiLogoInstagram,BiPhone } from 'react-icons/bi'
import {CiMail} from 'react-icons/ci'
import { POST } from '../../../utils/ServerControl'

const AdminContact = () => {
    const[instagram,setInstagram] = useState('')
    const[facebook,setFacebook] = useState('')
    const[name,setName] = useState('')
    const[phone,setPhone] = useState('')
    const[mail,setMail] = useState('')

    useEffect(() => {
      POST(process.env.REACT_APP_SETTING, 'select',[]).then((res) => {
        setInstagram(res[0].instagram)
        setFacebook(res[0].facebook)
        setName(res[0].name)
        setPhone(res[0].phone)
        setMail(res[0].mail)
      })
    }, [])

    const updateHandel = () => {
      POST(process.env.REACT_APP_SETTING, 'update',[{name:name,instagram:instagram,facebook:facebook,phone:phone,mail:mail}]).then((res) => {
        console.log(res)
      })
    }

  return (
    <div className='w-full flex items-center justify-center flex-col flex-1 gap-6 p-8'>
        <span className='w-full flex flex-row items-start justify-center gap-1 rounded-md border-2 border-gray-300 focus:border-orange-400 focus:outline-none'>
            <label htmlFor='name' className='text-base whitespace-nowrap leading-10 font-bold border-r border-black p-2'>Marka Adı</label>
            <input value={name} onChange={(e)=>{setName(e.target.value)}} id='name' className='w-full h-full focus:outline-none px-2'></input>
       </span>
        
      <span className='flex items-center justify-center w-full'>
        <label htmlFor='instagram' className='text-2xl font-bold'>
        <BiLogoInstagram size={48}></BiLogoInstagram>
        </label>
        <input  id='instagram' value={instagram} onChange={e=>{setInstagram(e.target.value)}} className='w-full p-2 rounded-md border-2 border-gray-300 focus:border-orange-400 focus:outline-none' placeholder='İnstagram profilinizi giriniz...'></input>
      </span>
 
      <span className='flex items-center justify-center w-full'>
        <label htmlFor='facebook' className='text-2xl font-bold'>
        <BiLogoFacebookCircle size={48}></BiLogoFacebookCircle>
        </label>
        <input id='facebook' value={facebook} onChange={e=>{setFacebook(e.target.value)}} className='w-full p-2 rounded-md border-2 border-gray-300 focus:border-orange-400 focus:outline-none' placeholder='Facebook profilinizi giriniz...'></input>
      </span>


      <span className='flex items-center justify-center w-full'>
        <label htmlFor='phone' className='text-2xl font-bold'>
        <BiPhone size={48}></BiPhone>
        </label>
        <input id='phone' onChange={e=>{setPhone(e.target.value)}} className='w-full p-2 rounded-md border-2 border-gray-300 focus:border-orange-400 focus:outline-none' placeholder='Telefon numaranızı giriniz...'></input>
      </span>
      
      <span className='flex items-center justify-center w-full'>
        <label htmlFor='mail' className='text-2xl font-bold'>
        <CiMail size={48}></CiMail>
        </label>
        <input id='mail' onChange={e=>{setMail(e.target.value)}} type='email' className='w-full p-2 rounded-md border-2 border-gray-300 focus:border-orange-400 focus:outline-none' placeholder='Mail adresinizi giriniz...'></input>
        
      </span>
      <p className='bg-gray-200 p-2 text-center font-medium rounded-md'>
          Değerlendirme sonuçlarınızı mail adresiniz üzerinden kontrol edebilirsiniz.
      </p>

      <button  className='w-full p-2 rounded-md bg-orange-400 text-white font-bold'>Kaydet</button>


        

    </div>
  )
}

export default AdminContact