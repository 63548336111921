import React, { useState } from 'react'
import İtemCard from '../Components/İtemCard'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {İData} from '../Data/İtemData'
import { POST } from '../utils/ServerControl'
const İtem = ({}) => {
  const {id} =useParams();
  const [data,setData] =useState([]);
  useEffect(()=>{

      POST(process.env.REACT_APP_URUN,'select',[]).then((res)=>{
       setData(res.filter((item)=>item.categories==id))
      }
      )
  },[id])
 

  return (
    <div className='flex flex-col p-2  gap-4 min-h-screen '>
     <div className='min-h-[3rem] w-full flex items-center justify-center'>
        <h1 className='text-xl tracking-wides'>
          {
            id
          }
        </h1>
     </div>
      {
       data.map((item,index)=>{
        return(
          <İtemCard item={item} key={index}></İtemCard>
        )
       })
      }
    </div>
  )
}

export default İtem