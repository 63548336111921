import React, { useEffect } from 'react'
import logo from '../../image/logo.png'
import { useState } from 'react'
import md5 from 'md5';
import { POST } from '../../utils/ServerControl';


const Login = () => {

    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [log,setlog] = useState('');

    const login = async ()=>{
      
      await  POST(process.env.REACT_APP_LOG,'login',[{name:'username',value:username},{name:'password',value:password}])
      .then((res)=>{setlog(res)})
        .catch((err)=>{setlog(err)})
       if(log){
        window.location.href='/Dashboard/categories'
        window.sessionStorage.setItem('token',md5(username+password))
       }else if(username === '' || password === ''){
        alert('Lütfen Boş Alan Bırakmayınız')

       }else{
        alert('Kullanıcı Adı veya Şifre Yanlış')
       }
             
           

    }
    useEffect(()=>{
        if(window.sessionStorage.getItem('token')){
            window.location.href='/Dashboard/categories'
        }
    },[])
   

  return (
    <div className='w-full h-screen bg-slate-100'>
        <div className='flex items-center justify-center h-full'>
            <div className='flex flex-col items-center justify-center gap-2 p-2 bg-white rounded-md shadow-xl'>
                <h1 className='text-2xl font-light'>
                   <img src={logo} className='w-32' />
                </h1>
                <input onChange={(e)=>{setUsername(e.target.value)}} type='text' placeholder='Kullanıcı Adı' className='w-full p-2 border-2 border-slate-100 rounded-md outline-none focus:border-orange-400'></input>
                <input onChange={(e)=>{setPassword(e.target.value)}} type='password' placeholder='Şifre' className='w-full p-2 border-2 border-slate-100 rounded-md outline-none focus:border-orange-400'></input>
                <button onClick={()=>{login()}} className='w-full p-2 bg-orange-400 rounded-md text-white font-light'>
                    Giriş Yap
                </button>
            </div>
        </div>        
    </div>
  )
}

export default Login