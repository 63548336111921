import React from 'react'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import { useState } from 'react'
import {RxCross2} from 'react-icons/rx'
import {SlPicture} from 'react-icons/sl'
import {HiTemplate} from 'react-icons/hi'
import {IoFastFoodOutline} from 'react-icons/io5'
import {BsFillGearFill} from 'react-icons/bs'
import {BsFacebook} from'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsWhatsapp } from 'react-icons/bs'
import {BsQrCode} from 'react-icons/bs'
import {IoExit} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { POST } from '../utils/ServerControl'
import { Menu } from '@headlessui/react'
import {PiSelectionBackgroundBold} from 'react-icons/pi'
import {BsPhone} from 'react-icons/bs'

const DashboradSidebar = ({size}) => {

    const [open,setOpen] = useState(false);

    const logoutHandel = ()=>{
        window.sessionStorage.removeItem('token');
        window.location.href='/login'
    }
    const [data,setData] = useState('')
    useEffect(() => {
      POST(process.env.REACT_APP_SETTING,'select',[]).then(res=>{
        setData(res[0])
      }
      )
    },[])
  return (
   <div>
   <HiOutlineMenuAlt2  className={open?'text-white':' shadow-2xl shadow-black p-1 rounded-lg'} size={size} onClick={()=>{setOpen(!open)}}></HiOutlineMenuAlt2>
   <div className={open?'w-full z-50 bg-white  top-0 left-0 h-screen absolute flex flex-col items-start justify-start p-4 transition-all opacity-100 delay-200':'hidden opacity-0'}>
        <div className='w-full  flex flex-col items-end justify-start z-10 '>
            <RxCross2 className='z-20 shadow-xl p-1 rounded-lg' onClick={()=>{setOpen(false)}} color='black' size={size}></RxCross2>
        </div>
       <div className='flex flex-col gap-16 items-center justify-between w-full h-full'>
       <div className='w-full items-center justify-center flex'>
          <img src={process.env.REACT_APP_BASE_URL+data.logo} className='w-40'></img>
        </div>
        <div className='w-full flex flex-col gap-3 h-full flex-1'>
          <Link to='/Dashboard/categories' onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
          <HiTemplate size={18} color='grey'></HiTemplate>
          <label className='text-lg text-slate-500 font-medium cursor-pointer'>Kategoriler</label>
          </Link>
          <Link to='/Dashboard/items' onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
          <IoFastFoodOutline size={18} color='grey'></IoFastFoodOutline>
          <label className='text-lg text-slate-500 font-medium cursor-pointer'>Ürünler</label>
         </Link>
         <Link to='/Dashboard/code' onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
          <BsQrCode size={18} color='grey'></BsQrCode>
          <label className='text-lg text-slate-500 font-medium cursor-pointer'>QR Oluştur</label>
         </Link>
          <span className='w-full border  border-slate-100'>

          </span>
         <div className='w-full'>
         <Menu as="div" className="relative inline-block text-left flex-1 w-full  ">
            <Menu.Button className="flex items-center gap-2 justify-start w-full p-1">
            
            <BsFillGearFill size={16} color='grey'></BsFillGearFill>
            <label className='text-lg text-slate-500 font-normal cursor-pointer'>Ayarlar</label>
      
            </Menu.Button>
          <Menu.Items className="origin-top-right absolute left-0 mt-2 w-full overflow-hidden rounded-md shadow-lg bg-white ring-opacity-5 focus:outline-none">
            <div className='flex items-center flex-col overflow-hidden gap-2 w-full flex-1'>
          <Menu.Item as='button'  className='w-full text-left p-2 text-black flex items-center justify-start gap-1'>
            <Link to='/Dashboard/settings/logo' onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
             <SlPicture size={18} color='grey'></SlPicture>
              <h1 className='font-semibold text-gray-600'>
                Logo ayarları
              </h1>
            </Link>
          </Menu.Item>
          <Menu.Item as='button'  className='w-full text-left p-2 text-black flex items-center justify-start gap-1'>
          <Link to={'/Dashboard/settings/background'} onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
          <PiSelectionBackgroundBold size={18} color='grey'></PiSelectionBackgroundBold>
              <h1 className='font-semibold text-gray-600'>
                Arkaplan ayarları
              </h1>
          </Link>
          </Menu.Item>
          <Menu.Item as='button'  className='w-full text-left p-2 text-black flex items-center justify-start gap-1'>
         <Link to={'/Dashboard/settings/contact'} onClick={()=>{setOpen(false)}} className='flex gap-2 items-center justify-start'>
         <BsPhone size={18} color='grey'></BsPhone>
              <h1 className='font-semibold text-gray-600'>
                İletişim ayarları
              </h1>
         </Link>
          </Menu.Item>
            </div>
          </Menu.Items>
          </Menu>
         </div>
         

          
          <Link to='/Dashboard/categories'  onClick={()=>{logoutHandel()}} className='flex gap-2 items-center justify-start'>
            <IoExit size={16} color='red'></IoExit>
          <label className='text-lg text-red-500 font-normal cursor-pointer'>Çıkış Yap</label>
          </Link>
        </div>
       </div>
       <div className='flex flex-row gap-5 items-center justify-center w-full'>
          <BsFacebook size={18} color='#a19754 border cursor-pointer'/>
          <BsInstagram size={18} color='#a19754 border cursor-pointer'/>
          <BsWhatsapp size={18} color='#a19754 border cursor-pointer'/>
       </div>
   </div>
   </div>
  )
}

export default DashboradSidebar