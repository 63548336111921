import React, { useEffect } from 'react'
import { useState } from 'react'
import {BiLogoInstagram,BiLogoFacebookCircle} from 'react-icons/bi'
import DashboradSidebar from '../../../Components/DashboradSidebar'
import { Outlet } from 'react-router-dom'




const AdminSettings = () => {


  


  return (
    <div className='w-full h-screen flex flex-col items-center justify-start gap-2 p-4'>
       <div className='w-full flex items-center justify-end'>
       <DashboradSidebar size={48}></DashboradSidebar>
       </div>
       <Outlet></Outlet>
     

      

    
    </div>
  )
}

export default AdminSettings