import React, { useEffect ,useState } from 'react'
import {IoCaretBackOutline} from 'react-icons/io5'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom';
import { POST } from '../utils/ServerControl';

const Nav = ({back , menu,icon=true}) => {
  const navigate =useNavigate();
  const [logo,setLogo] = useState('')
  useEffect(() => {
    POST(process.env.REACT_APP_SETTING,'select',[]).then(res=>{
      setLogo(res[0].logo)
    }
    )
  },[])
  return (
    <div className='w-full flex items-center justify-between relative p-4'>
        {
            back?<IoCaretBackOutline size={36} onClick={()=>{navigate(-1)}} className=' shadow-xl p-1 rounded-lg'/>:null
        }
        <div>
          {
            icon === true ? <img src={process.env.REACT_APP_BASE_URL+logo} className='w-40'></img>:null
          }
        </div>
       {
        menu? <Sidebar size={36}></Sidebar>:null
       }

    </div>
  )
}

export default Nav