import React, { useEffect ,useState } from 'react'
import { Link } from "react-router-dom";
import { POST } from '../utils/ServerControl';

const Home = () => {


  const [background,setBackground] = useState('')
  const [first,setFirst] = useState('')
  useEffect(() => {
    POST(process.env.REACT_APP_SETTING,'select',[]).then(res=>{
      setBackground(res[0].background)
    })
   
  },[])
  useEffect(() => {
    POST(process.env.REACT_APP_KATEGORI,'select',[]).then(res=>{
      setFirst(res[0].name)
    }
    )
  },[])

  return (
    <div className='min-w-full min-h-screen bg-center bg-contain flex lg:bg-contain bg-no-repeat lg:bg-slate-300 flex-col items-center justify-center' style={{backgroundImage:`url(${process.env.REACT_APP_BASE_URL+background})`}}>
       <Link to={'/menu/'+first} className='border-2 lg:p-8  border-orange-600 px-8 py-4 rounded-3xl text-xl font-bold text-orange-400 mt-96 link cursor-pointer text-center lg:w-1/5' >
        Menu için tıklayın
       </Link>
    </div>
  )
}

export default Home