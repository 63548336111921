import { closeModal } from "../utils/modal"
import {IoCloseCircleSharp} from 'react-icons/io5'
import {useEffect, useState} from 'react'
import {GET, POST} from '../utils/ServerControl'
export default function Edit({item}) {

   
   const [name, setName] = useState(item.name)
  
   const [priview, setPriview] = useState()
   const[img, setImg] = useState(item.img)
     const filehandel = (e) => {
            setPriview(URL.createObjectURL(e.target.files[0]))
            
     }
    
   const uploadHandel = () => {
    POST(process.env.REACT_APP_KATEGORI,'edit',[{name:'sno',value:item.sno},{name:'name',value:name},{name:'file',value:img}]).then((res)=>{
      window.location.reload()

      }
      )
      
   }



   return (
        <div className="w-full z-50  h-screen absolute top-0 left-0 p-2 bg-slate-300 rounded-lg">
            <div className="flex items-center justify-end w-full ">
               <IoCloseCircleSharp size={44} onClick={closeModal}></IoCloseCircleSharp>
            </div>
            <h1 className="text-orange-400 font-semibold text-xl ml-4 mb-4">Kategori Düzenle</h1>
            <div className="flex flex-col items-center justify-center gap-4">
               <span className="flex w-full  items-center justify-center bg-white rounded-md">
                    <label htmlFor="name" className="text-black font-semibold uppercase text-sm whitespace-nowrap border-r border-black p-2 h-full ">Kategori Adı</label>
                    <input id="name" value={name}  onChange={(e)=>{setName(e.target.value)}} type="text" className="w-full rounded-md outline-none p-2 valid:bg-white"></input>
               </span>
              {!priview?<img src={process.env.REACT_APP_BASE_URL+img} className="w-full h-full"></img>:<img src={priview} className="w-full h-full"></img>}
              <label htmlFor="file" className="text-white w-full text-center bg-indigo-600 font-semibold uppercase text-sm whitespace-nowrap p-2 h-full ">Resim seçiniz</label><input id="file" onChange={(e)=>{filehandel(e)}} type="file" className="w-full hidden  rounded-md outline-none p-2"></input>
            <span className="w-full">
            <button onClick={()=>{uploadHandel()}} className={!name || !img?'w-full h-12 tracking-wider pointer-events-none text-white bg-green-200  ':'  w-full h-12 tracking-wider text-white bg-green-500'}>
                  Düzenle
               </button>
            </span>
            </div>
        </div>
    )

}
