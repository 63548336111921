import React from 'react'

export const POST = async (path,action,data=false)  =>{
   
    const formdata = new FormData()
    formdata.append('action',action)
    data.map((item)=>{
    formdata.append(item.name,item.value)
    })
    const res = await fetch(path,{
        method:'POST',
       
        body:formdata
    })
    const resData = await res.json()
    return resData

}
export const GET = async (path,action)  =>{
       
    const formdata = new FormData()
    formdata.append('action',action)
    const res = await fetch(path,{
        method:'GET',
        headers:{
            'Content-Type':'multipart/form-data'
        },
        body:formdata
    })
    const resData = await res.json()
    return resData

}