import React from "react";
import Nav from "./Components/Nav";
import {Cat} from './Data/CategoriesData'
import Categories from "./Components/Categories";
import Slider from "./Components/Slider";
import İtem from './pages/İtem'
import Home  from "./pages/Home";
import { Provider } from "react";
import store from "./store";



function App() {
  return (

  <div className="bg-slate-50 w-full h-screen flex flex-col ">
    <Home></Home>
   </div>
 
  );
}

export default App;
