import React, { useEffect } from 'react'
import Nav from '../Components/Nav'
import Rating from 'react-rating'
import {AiFillStar,AiOutlineStar} from 'react-icons/ai'
import { useState } from 'react'

const FeedBack = () => {


    const[question1,setQuestion1] = useState()
    const[question2,setQuestion2] = useState('')
    const[question3,setQuestion3] = useState('')
    const[question4,setQuestion4] = useState('')
    const[question5,setQuestion5] = useState()
    const[question6,setQuestion6] = useState('')
    const[question7,setQuestion7] = useState('')
    const[question8,setQuestion8] = useState('')

   
  return (
    <div className='w-full min-h-screen h-auto flex flex-col  items-start justify-center overflow-y-scroll '>
        <Nav back={true} icon={false}></Nav>
        <div className='w-full min-h-screen flex-1 flex flex-col '>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-base font-semibold'>
                      İşletmemizi ilk defa mı ziyaret ediyorsunuz?
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                    <button onClick={e=>{setQuestion1(true)}} className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'>
                        Evet
                    </button>
                    <button onClick={e=>{setQuestion1(false)}} className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'>
                        Hayır
                    </button>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-sm font-bold'>
                     Genel olarak işletmemizden memnun musunuz?
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                    <Rating 
                    emptySymbol={<AiOutlineStar className='text-[#988d42] text-2xl'/>}
                    fullSymbol={<AiFillStar className='text-[#988d42] text-2xl'/>}
                    onChange={e=>{setQuestion2(e)}}
                    >

                    </Rating>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-sm font-bold'>
                Hijyeni nasıl değerlendirirsiniz?
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                    <Rating 
                    emptySymbol={<AiOutlineStar className='text-[#988d42] text-2xl'/>}
                    fullSymbol={<AiFillStar className='text-[#988d42] text-2xl'/>}
                    onChange={e=>{setQuestion3(e)}}
                    >

                    </Rating>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-sm font-bold'>
                Yemeklerimizin lezzetini nasıl değerlendirirsiniz?
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                    <Rating 
                    onChange={e=>{setQuestion4(e)}}
                    emptySymbol={<AiOutlineStar className='text-[#988d42] text-2xl'/>}
                    fullSymbol={<AiFillStar className='text-[#988d42] text-2xl'/>}
                    >

                    </Rating>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-base font-semibold'>
                      Tekrar ziyaret etmek ister misiniz?
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                    <button onChange={e=>{setQuestion5(true)}} className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'>
                        Evet
                    </button>
                    <button onChange={e=>{setQuestion5(false)}} className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'>
                        Hayır
                    </button>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-base font-semibold'>
                        Yorumunuz
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                   <input onChange={e=>{setQuestion6(e.target.value)}} type='text' className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'/>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-base font-semibold'>
                        Adınız
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                   <input onChange={e=>{setQuestion7(e.target.value)}} type='text' className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'/>
                </span>
            </span>
            <span className='w-full flex flex-col items-center justify-center gap-5 p-6'>
                <h1 className='text-base font-semibold'>
                        Mail Adresiniz
                </h1>
                <span className='flex items-center justify-center gap-4 w-full'>
                   <input onChange={e=>{setQuestion8(e.target.value)}} type='text' className='w-full p-2 border-[#988d42] border text-[#988d42] text-xl rounded-full  font-light'/>
                </span>
            </span>
           
            <button className='w-full h-20 p-4 text-lg text-white text-center  bg-[#988d42]'>
                Gönder
            </button>
        </div>

    </div>
  )
}

export default FeedBack