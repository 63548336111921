import React, { useEffect } from 'react'
import Nav from '../Components/Nav'
import Slider from '../Components/Slider'
import { Outlet } from 'react-router-dom'

const Menu = () => {
 
  return (
    <div className='flex flex-col h-screen gap-2'>
        <Nav back menu></Nav>
        <Slider></Slider>
        <div className='w-full overflow-y-scroll  '>
        <Outlet></Outlet>
        </div>
    </div>
  )
}

export default Menu