import React from 'react'
import {useState} from 'react'
import {PiBowlFoodThin} from 'react-icons/pi'
import {createModal,useModals} from '../../../utils/modal'
import { POST } from '../../../utils/ServerControl'
const Card = ({item}) => {
  
  const modals = useModals()


  const deleteHandel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('action','delete')
    formData.append('sno',item.sno)
    
    POST(process.env.REACT_APP_KATEGORI,'delete',[{name:'sno',value:item.sno}]).then((res)=>{
      console.log(res)
      if(res.status){
        window.location.reload()
      }
    }
    )

  }
  const editHandel = () => {
   window.scrollTo(0,0)
    createModal('edit',item)
  }
 
  return (
    
     <div className='w-full   flex flex-col gap-3 border bg-slate-200/50 border-slate-300 p-3 shadow-xl rounded-md'>
      <div className='flex items-start justify-center flex-1 gap-3'>
      
      <img src={process.env.REACT_APP_BASE_URL+item.img} alt='' className='w-2/3 h-full rounded-md max-h-[160px] object-cover'></img>
      <div className='flex flex-1 flex-col gap-4 '>
        <h1 className='text-base w-full  uppercase text-black ml-2 font-semibold tracking-wide '>
          {item.name}
        </h1>
        
      </div>
      
      </div>
     
     
      <div className='flex items-center justify-center w-full gap-3  '>
        <button onClick={()=>{editHandel(item)}} className='bg-indigo-500 flex-1 rounded-md p-1 text-white'>Düzenle</button>
        <button onClick={(e)=>{deleteHandel(e)}} className='bg-red-500 flex-1 rounded-md p-1 text-white'>Sil</button>
      </div>
     </div>

  )
}

export default Card