import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { POST } from '../utils/ServerControl';
import Categories from './Categories';




const Slider = () => {


  const [item,setİtem] = useState([]);
  useEffect(()=>{
   POST(process.env.REACT_APP_KATEGORI,'select',[]).then((res)=>{
     setİtem(res)
    })
  },[])
    
  
  return (
    <Swiper 
    style={{
        "--swiper-navigation-color": "#000",
        "--swiper-navigation-size": "10px",
        "backgroundColor ":"red", 
      }}
    navigation={true} 
    modules={[Navigation]}
    className='w-full flex items-center justify-center mySwiper shadow-md min-h-[7rem] shadow-black/20 rounded-md '
    slidesPerView={3}
    spaceBetween={3}
    
   
     >
      {
        item.map((data,index)=>{
            return(
              
                <SwiperSlide  key={index}  >
                     <Categories item={data} key={index}></Categories>
                </SwiperSlide>
              
            )
        })
      }
      </Swiper>
  )
}

export default Slider