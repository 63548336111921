import React, { useEffect } from 'react'
import DashboradSidebar from '../../Components/DashboradSidebar';
import { Outlet } from 'react-router-dom';


const Dashboard = () => {
    const token = window.sessionStorage.getItem('token');
    useEffect(()=>{
        
        if(!token){
            window.location.href='/login'
        }
    },[token])
  return (
    <div className='w-full h-screen bg-slate-50 flex flex-col p-3'>
        <div className='w-full flex items-center justify-end'>
            <DashboradSidebar size={40}></DashboradSidebar>
        </div>
        <div>
            <Outlet></Outlet>
        </div>

    </div>
  )
}

export default Dashboard