import React from 'react'
import QRCode from "react-qr-code";
import {  EmailShareButton,FacebookShareButton,  WhatsappShareButton,FacebookIcon,EmailIcon,WhatsappIcon} from "react-share";


const AdminCode = () => {
  return (
    <div className='w-full flex flex-col gap-12 items-center justify-center h-screen'>
      
      <QRCode value={process.env.REACT_APP_BASE_URL}  />
      
      <span className='flex items-center justify-around gap-4'>
      <EmailShareButton url={process.env.REACT_APP_BASE_URL} >
        <EmailIcon className='rounded-full'>İnstagram</EmailIcon>
      </EmailShareButton>
      <FacebookShareButton url={process.env.REACT_APP_BASE_URL} >
        <FacebookIcon className='rounded-full'>Facebook</FacebookIcon>
      </FacebookShareButton>
      <WhatsappShareButton url={process.env.REACT_APP_BASE_URL} >
        <WhatsappIcon className='rounded-full'>Whatsapp</WhatsappIcon>
      </WhatsappShareButton>
      </span>
    </div>
  )
}

export default AdminCode