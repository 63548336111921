import React from 'react'
import { Link } from 'react-router-dom'

const Categories = ({item}) => {
  return (
    <Link to={"/menu/"+item.name} className='flex flex-col  max-w-sm items-center justify-center gap-2 mb-12'>
        <img src={process.env.REACT_APP_BASE_URL+item.img} alt={item.name} className='rounded-md max-w-[7.5rem] overflow-hidden'  ></img>
        <h1 className='text-[#a29955] break-words text-xs text-center'>
            {
                item.name
            }
        </h1>
    </Link>
  )
}
export default Categories