import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { POST } from '../../../utils/ServerControl'

const AdminBackground = () => {
    const[bg,setBg] = useState('')
    const [preview, setPreview] = useState('')
    useEffect(() => {
        POST(process.env.REACT_APP_SETTING, 'select',[]).then((res) => {
          setPreview(process.env.REACT_APP_BASE_URL+res[0].background)
        })
      }, [])

    const updateHandel = () => {
    }
  return (
    <div>
          
      <span className='w-full flex flex-col items-start justify-center gap-1'>
      <div className='w-full flex items-center justify-center'>
            {
        !bg?<img src={preview} className='w-full h-auto'></img>:<img src={URL.createObjectURL(bg)} className='w-full h-auto'></img>
            }
        </div>
        <label htmlFor='bg' className={bg?'text-base text-white text-center p-1 py-3 font-bold w-full bg-green-400 border-dashed border-black border rounded-md':'text-base text-black text-center p-1 py-3 font-bold w-full bg-white border-dashed border-black border rounded-md'}>
          {bg?bg.name:'Arkaplan resmi seçmek için tıklayın'}
        </label>
        <input id='bg' onChange={(e)=>{setBg(e.target.files[0])}} type='file' className='hidden' ></input>
      
      </span>
     
      <span className='w-full'>
        <button   className={' w-full p-2 rounded-md bg-orange-400 text-white font-bold'}>Kaydet</button>
      </span>
    </div>
  )
}

export default AdminBackground