import React from 'react'
import {useState} from 'react'
import İtemControl from '../components/İtemControl'
import İtemCard from '../components/İtemCard'
import { useEffect } from 'react'
import { POST } from '../../../utils/ServerControl'

const Adminİtem = () => {

  const [data, setData] = useState([])

  useEffect(() => {
    POST(process.env.REACT_APP_URUN, 'select',[]).then((res) => {
      setData(res)
    })
  }, [])

  return (
    <div className='flex flex-col py-4 gap-4 relative h-screen'>
    <İtemControl data={data} setData={setData}/>
      {
      data?.map((item,index) => {
        return <İtemCard key={index} item={item}/>
      }
      )
      }
    
  </div>
  )
}

export default Adminİtem